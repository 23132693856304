

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLanguageIntial } from "./store/messageCatalog";
import { getlanguageHomeAPI } from "./services/api";
 import getAssets from "./assets/data/brandingAssets.json";
import { useToast } from "./context/ToastContext";
import { loadGoogleFont } from "./utils/loadGoogleFont";

const CMS = ({ children }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { general } = useToast();

  useEffect(() => {
    const getResponse = (response) => {
        if (response.result === "SUCCESS") {
            dispatch(setLanguageIntial({k:i18n.language,v:response.data}))
        }
    };
    getlanguageHomeAPI(i18n.language,"header", dispatch, getResponse)
    getlanguageHomeAPI(i18n.language,"partner", dispatch, getResponse)
    getlanguageHomeAPI(i18n.language,"affiliate", dispatch, getResponse)
    getlanguageHomeAPI(i18n.language,"home", dispatch, getResponse)
    getlanguageHomeAPI(i18n.language,"labels", dispatch, getResponse)
    getlanguageHomeAPI(i18n.language,"contact", dispatch, getResponse)
    getlanguageHomeAPI(i18n.language,"aboutUs", dispatch, getResponse)
    getlanguageHomeAPI(i18n.language,"messageCatalog", dispatch, getResponse)
    getlanguageHomeAPI(i18n.language,"validations", dispatch, getResponse)
    getlanguageHomeAPI(i18n.language,"myAccount", dispatch, getResponse)
    getlanguageHomeAPI(i18n.language,"themeColors", dispatch, getResponse)
}, [i18n.language,dispatch])

useEffect(() => {
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
            dispatch(setLanguageIntial({k:i18n.language,v:response.data}))
            let assert = response.data.general
             trimColorCode(assert['GEN0006.defaultPrimaryFont']) === "false" && trimColorCode(assert["GEN0006.fontBase"]) && loadGoogleFont(trimColorCode(assert["GEN0006.fontBase"]))
             trimColorCode(assert['GEN0006.defaultSecondaryFont']) === "false" && trimColorCode(assert["GEN0006.fontSecondary"]) && loadGoogleFont(trimColorCode(assert["GEN0006.fontSecondary"]))
      }
  };
  getlanguageHomeAPI(i18n.language,"general", dispatch, getResponse)
  }, [i18n.language, dispatch])


  const trimColorCode = (colors) => colors ? colors.replace(/"/g, '').trim(): '';
  const defaultPrimaryFont = trimColorCode(general?.['GEN0006.defaultPrimaryFont']);
  const primaryFont = trimColorCode(general?.['GEN0006.fontBase']);
  const fontPrimary  = defaultPrimaryFont === "true" ? getAssets.fonts['font-base'] : primaryFont?? getAssets.fonts['font-base'] ;
  const defaultSecondaryFont = trimColorCode(general?.['GEN0006.defaultSecondaryFont']);
  const secondaryFont = trimColorCode(general?.['GEN0006.fontSecondary']);
  const fontSecondary  = defaultSecondaryFont === "true" ? getAssets.fonts['font-secondary'] : secondaryFont?? getAssets.fonts['font-secondary'] ;
  document.documentElement.style.setProperty('--font-base', fontPrimary ? fontPrimary : getAssets.fonts['GEN0006.fontBase'] );
  document.documentElement.style.setProperty('--font-secondary', fontSecondary ? fontSecondary : getAssets.fonts['GEN0006.fontSecondary']);
  /* 
  document.documentElement.style.setProperty('--general_font_size', getAssets.fonts['general_font_size']);
  document.documentElement.style.setProperty('--white', trimColorCode('#ffffff'));
  document.documentElement.style.setProperty('--black', trimColorCode('#283249'));
  document.documentElement.style.setProperty('--error-msg', trimColorCode('#ff1900'));
  document.documentElement.style.setProperty('--primary', trimColorCode(themeColors.PRIMARY));
  document.documentElement.style.setProperty('--secondary', trimColorCode(themeColors.SECONDARY));
  document.documentElement.style.setProperty('--teritary', trimColorCode(themeColors.TERITARY));
  document.documentElement.style.setProperty('--quaternary', trimColorCode(themeColors.QUATERNARY));
  document.documentElement.style.setProperty('--quinary', trimColorCode(themeColors.QUINARY));
  document.documentElement.style.setProperty('--mainmenu', trimColorCode(themeColors.MAINMENU));
  document.documentElement.style.setProperty('--mainmenuover', trimColorCode(themeColors.MAINMENUOVER));
  document.documentElement.style.setProperty('--btnprimary', trimColorCode(themeColors.BTNPRIMARY));
  document.documentElement.style.setProperty('--btnprimaryover', trimColorCode(themeColors.BTNPRIMARYOVER));
  document.documentElement.style.setProperty('--btnsecondary', trimColorCode(themeColors.BTNSECONDARY));
  document.documentElement.style.setProperty('--btnsecondaryover', trimColorCode(themeColors.BTNSECONDARYOVER));
  document.documentElement.style.setProperty('--input-field', trimColorCode(themeColors.INPUTFIELD));
  document.documentElement.style.setProperty('--input-border', trimColorCode(themeColors.INPUTFIELDBORDER));
  document.documentElement.style.setProperty('--dropdown-bg', trimColorCode(themeColors.DROPDOWNBG));
  document.documentElement.style.setProperty('--dropdown-selected', trimColorCode(themeColors.DROPDOWNSELECTED)); */

  return <>{children}</>;
};
export default CMS;
