import React, { useEffect, useState } from "react";
import * as PR from "../../prime-modules/index";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { isIOS, osVersion, isMobile } from 'react-device-detect';
import { cartFilterActions } from "../../store/cart";
import { affiliateTrackingInfoActions } from "../../store/affiliateTracking";
import { Checkmark } from "../../assets/images";
import EsimActivationDialog from "../../shared/components/EsimActivationDialog";
import HeaderMenu from "../layout/HeaderMenu";
import { useToast } from "../../context/ToastContext";

const PaymentSummary = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {labels } = useToast();
    const responseData = useSelector(state => state.payment.paymentResponse);
    const profileStatus = responseData?.paymentResponse?.profileStatus;
    const smdpAddress = responseData?.paymentResponse?.smdpAddress;
    const matchingId = responseData?.paymentResponse?.matchingId;

    const [activeeSIM, setActiveeSIM] = useState(false);
    const [payRes, setPayRes] = useState(null);
    const [qrData, setQRData] = useState('');

    useEffect(() => {
        const viewedPaymentSummary = localStorage.getItem("VIEWED_PAYMENT_SUMMARY");
        if (viewedPaymentSummary && !profileStatus && !smdpAddress && !matchingId) {
            navigate("/");
            return;
        }

        if (profileStatus && smdpAddress && matchingId) {
            setPayRes(responseData);
            if (responseData?.qrCode) {
                setQRData(`data:image/png;base64,${responseData?.qrCode}`);
            }
            dispatch(cartFilterActions.deleteCatalogCart());
            dispatch(cartFilterActions.setCatalogCartAmount(0));
            dispatch(affiliateTrackingInfoActions.deleteAffiliateTrackingInfo());
        }
    }, [dispatch, matchingId, navigate, profileStatus, responseData, smdpAddress]);

    useEffect(() => {
        if (payRes) {
            localStorage.setItem("VIEWED_PAYMENT_SUMMARY", true);
            setTimeout(() => {
                localStorage.removeItem("PAYMENT-RESPONSE");
            }, 1000);
        }
    }, [payRes]);

    const downloadQRCode = (qrcode) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = qrcode;
        downloadLink.download = "QR_Code.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <>
            <section className="inner-header-section aboutus-header-section payment_summary_header">
                <div className="header">
                    <header>
                        <HeaderMenu />
                    </header>
                    <div className="fixed-height"></div>
                </div>
            </section>
            {
                payRes && (
                    <section className="paymentsummary-qr-section">
                        <div className="payment-summary">
                            <PR.Image src={Checkmark} alt={labels?.LBL0052} />
                            <h2>{labels?.LBL0052}</h2>
                            <p>{labels?.LBL0053}.</p>
                            {qrData && (qrData !== '') &&
                                <>
                                    {(payRes?.paymentResponse?.profileStatus && (payRes?.paymentResponse?.profileStatus === 'Released')) ?
                                        <>
                                            <span className="scan-text">{labels?.LBL0054}</span>

                                            <img src={qrData} id="qrCodeEl" alt="qrcode" className="qr-code" />

                                            {payRes?.email && payRes?.email !== "" &&
                                                <span className="scan-text">{labels?.LBL0055} <span className="payer-email"> {payRes?.email}</span> </span>
                                            }
                                            {payRes?.paymentResponse?.smdpAddress && payRes?.paymentResponse?.matchingId &&
                                                <EsimActivationDialog smdpAddress={payRes?.paymentResponse?.smdpAddress} matchingId={payRes?.paymentResponse?.matchingId} activeeSIM={activeeSIM} setActiveeSIM={setActiveeSIM} />}
                                            <br />
                                            <PR.Button type="button" label={labels?.LBL0056} value={labels?.LBL0056} className="download-button" onClick={() => downloadQRCode(qrData)} />
                                            {(isMobile && isIOS && (osVersion >= "17.4")) && <PR.Button onClick={() => setActiveeSIM(true)} type="button" label={labels?.LBL0057} className="download-button" />}
                                        </> :
                                        <span className="scan-text">{labels?.LBL0058}</span>
                                    }
                                </>
                            }
                        </div>
                    </section>
                )
            }
        </>
    );
};

export default PaymentSummary;